import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { InlineTextButton } from '../../../components';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../../util/types';
import config from '../../../config';

import css from '../ListingPage.module.css';

const Title = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    category,
    authorLink,
    showContactUser,
    onContactUser,
  } = props;

  const unitType = config.lineItemUnitType;

  const unitTranslationKey = 'ListingPage.perUnit';

  return (
    <div className={css.sectionHeading}>
      
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
        
          <FormattedMessage id="ListingPage.soldBy" values={{ name: authorLink }} />
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={onContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div>
      </div>
      
    </div>
  );
};

export default Title;
