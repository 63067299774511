import React from 'react';
import config from '../../config';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';

const GridGallery = props => {
  const { listing } = props;
  const images = listing.images;
  const { variantPrefix } = config.listing;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  console.log(images);
  return (
    <div className={css.gridGallery}>
      <img src={images[0].attributes.variants['scaled-large'].url} alt={listing.title} />
      <img src={images[1].attributes.variants['scaled-large'].url} alt={listing.title} />
    </div>
  );
};

export default GridGallery;
